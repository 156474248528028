import Time from "../../lib/utils/Time";
import React, {ReactNode} from "react";
import TimeLineData from "../../components/TimeLine/TimeLineData";

import MonthlyPlanningManualSettingsHeader
  from "./MonthlyPlanningWizard/WizardStepOne/MonthlyPlanningManualSettingsHeader/MonthlyPlanningManualSettingsHeader";
import MonthlyEmployeeManualSettingsLine
  from "../Dashboard/MonthlyPlanning/MonthlyEmployeeManualSettingsLine/MonthlyEmployeeManualSettingsLine";
import MonthlyEmployeeCell from "../Dashboard/MonthlyPlanning/MonthlyEmployeeCell/MonthlyExployeeCell";
import {TimeOffTypeDto, TimeOffUtils} from "../../api/timeOff/TimeOffApi";
import {ShiftDto} from "../../api/entity/ShiftDto";
import {DateTime} from "luxon";
import {MonthlyData, MonthlyEmployee} from "../Dashboard/MonthlyPlanning/MonthlyDTO";
import {FixedShiftDto} from "../../api/entity/FixedShiftDto";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import Interval = TimeLineData.Interval;

export interface EmployeeEdit {
  monthlyData: MonthlyData;
  employeeIndex: number;
  dayIndex: number
}

export default class MonthlyTimeLineUtils {


  public generateIntervalSettings(days: Date[], filteredEmployees: MonthlyEmployee[], timeOffTypes: TimeOffTypeDto[],
                                  shifts: ShiftDto[], fixedShifts: FixedShiftDto[],
                                  hideEmpty: boolean, lastDayMap: { [id: number]: string[] },
                                  searchValue: string,
                                  circuitFilter: string,
                                  onErrorClick: (workerName: string, errors: string[]) => void,
                                  onEdit?: (employee: EmployeeEdit) => void,
                                  editMode?: boolean): TimeLineMatrix[] {
    const timeLines: TimeLineMatrix[] = [];

    for (let i = 0; i < filteredEmployees.length; i++) {
      const employee = filteredEmployees[i];
      const intervals: Interval[] = []
      for (let j = 0; j < days.length; j++) {

        let filteredFest = timeOffTypes.filter(fest => fest.value == employee.turns[j]);
        let turn: { value: string, label: string } = filteredFest.length == 1 ? filteredFest[0] : {
          value: employee.turns[j],
          label: employee.turns[j]
        };

        let interval: Interval = {
          start: j,
          content: <MonthlyEmployeeManualSettingsLine turn={turn}
                                                      editMode={editMode}
                                                      hideEmpty={hideEmpty}
                                                      onEdit={onEdit ? () => onEdit({
                                                        monthlyData: {employees: filteredEmployees, days: days},
                                                        employeeIndex: i, dayIndex: j,
                                                      }) : null}/>
        };
        interval = this.setIntervalColor(hideEmpty, editMode, turn, interval);
        intervals.push(interval)
      }
      timeLines.push({
        intervals,
        title: <MonthlyEmployeeCell name={employee.name}
                                    errors={employee.errors}
                                    onClick={(workerName, errors) => onErrorClick(workerName, errors)}
                                    totalHours={this.calculateHoursForEmployee(employee, shifts, fixedShifts, days, lastDayMap)}/>
      })
    }
    return timeLines;
  }


  public generateHeaders(currentDate: DateTime, monthlyEmployees: MonthlyEmployee[]): React.ReactNode[] {
    const headers: ReactNode[] = []
    const days = Time.getDaysInMonth(currentDate)
    for (let i = 0; i < days.length; i++)
      headers.push(<MonthlyPlanningManualSettingsHeader
        day={days[i]}
        totalF={TimeOffUtils.getTotalFForDay(i, monthlyEmployees)}
        totalAR={TimeOffUtils.getTotalARForDay(i, monthlyEmployees)}
        totalGR={TimeOffUtils.getTotalGRForDay(i, monthlyEmployees)}
      />)
    return headers
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private setIntervalColor(hideEmpty: boolean, editMode: boolean, turn: { value: string; label: string }, interval: TimeLineData.Interval): TimeLineData.Interval {
    if (hideEmpty && !editMode && !turn.label)
      interval.color = "transparent"
    else if (TimeOffUtils.isF(turn.value))
      interval.color = "#ffd1d1"
    else if (TimeOffUtils.isAR(turn.value))
      interval.color = "#e8f3ff"
    else if (TimeOffUtils.isGR(turn.value))
      interval.color = "#ebf6da"
    return interval
  }

  private calculateHoursForEmployee(employee: MonthlyEmployee, shifts: ShiftDto[], fixedShifts: FixedShiftDto[], days: Date[], lastDayMap: { [id: number]: string[] }) {
    const turns = employee.turns;
    let totalHours = 0;
    for (let i = 0; i < turns.length; ++i) {
      const turn = turns[i]
      const shift = shifts.find(shift => shift.shiftCode == turn)
      const date = new Date(days[i]);

      if (shift) {
        // se è un turno di notte
        if (this.isNightShift(shift)) {
          // ed è l'ultimo giorno del mese
          if (this.isLastDayOfMonth(i, turns)) {
            // prendo la sua durata
            totalHours += (Time.HHMMToMinutes(shift.time))
          } else {
            // prendo durata + ore smonto
            totalHours += (Time.HHMMToMinutes(shift.time) + Time.HHMMToMinutes(shift.smontoH))
          }
          // è il primo giorno del mese ed è un turno di smonto (shift == S)
        // } else if (this.isTheFirstDayOfMonthAddTurnIsSmonto(i, turn)) {
        //   // se ho l'ultimo giorno della scorsa pianificazione
        //   if (lastDayMap[employee.id]) {
        //     const lastDayOfPreviousMonthShift = shifts.find(shift => shift.shiftCode == turn)
        //     // e l'ultimo giorno dello scorso mese è un turno di notte
        //     if (this.isNightShift(lastDayOfPreviousMonthShift)) {
        //       // prendo la durata smonto
        //       totalHours += Time.HHMMToMinutes(lastDayOfPreviousMonthShift.smontoH);
        //     }
        //   }
          // se il turno è F oppure AR oppure GR
        } else if (TimeOffUtils.isTimeOff(turn)) {
          // se il dipendente ha turni fissi per oggi
          if (this.employeeHasFixedShiftsToday(employee, fixedShifts, date)) {
            // prendo il turno fisso di oggi e ne ricavo la durata
            // (per le notti andrebbe fatta la stessa cosa di sopra)
            totalHours += (Time.HHMMToMinutes(this.getFixedShiftToday(employee, shifts, fixedShifts, date).time))
          } else {
            // altrimenti se è un giorno festivo o domentica, metto 0 altrimenti prendo 6,33
            totalHours += (this.isSundayOrHoliday(date) ? 0 : (6 * 60 + 20));
          }
          // se il turno è R oppure RN
        } else if (TimeOffUtils.isR(turn) || TimeOffUtils.isRN(turn)) {
          // se il dipendente oggi ha turni fissi
          if (this.employeeHasFixedShiftsToday(employee, fixedShifts, date)) {
            // prendiamo zero
            totalHours += 0
            // altirmenti
          } else {
            // se è festivo prendo 6,33 altrimenti 0
            totalHours += (Time.isHoliday(DateTime.fromJSDate(date)) ? (6 * 60 + 20) : 0);
          }
          // se il turno non è NOTTE, S, AR, GR, F, R, RN
        } else {
          totalHours += Time.HHMMToMinutes(shift.time)
        }
      }
    }

    // a prescindere, se l'ultimo giorno del mese scorso
    // ha una (o più) notte, prendiamo anche la durata degli smonti
    if (lastDayMap[employee.id]) {
      // per ognuno dei turni dell'ultimo giorno
      for(const t of lastDayMap[employee.id]){
        const lastDayOfPreviousMonthShift = shifts.find(shift => shift.shiftCode == t)
        // se è un turno di notte
        if (this.isNightShift(lastDayOfPreviousMonthShift)) {
          // prendo la durata smonto
          totalHours += Time.HHMMToMinutes(lastDayOfPreviousMonthShift.smontoH);
        }
      }
    }

    return totalHours;
  }

  private isLastDayOfMonth(i: number, turns: string[]) {
    return i == (turns.length - 1);
  }

  private isNightShift(shift: ShiftDto) {
    return shift.shiftType == "EVENING";
  }

  private isTheFirstDayOfMonthAddTurnIsSmonto(i: number, turn: string) {
    return i == 0 && TimeOffUtils.isS(turn);
  }

  private getWeekDay(date: Date): number {
    // we have to shift the days by one: monday->0 - sunday->6
    if (date.getDay() == 0) return 6
    return date.getDay() - 1
  }

  private employeeHasFixedShiftsToday(employee: MonthlyEmployee, fixedShifts: FixedShiftDto[], day: Date): boolean {
    for (const fixedShift of fixedShifts) {
      if (employee.id == fixedShift.userId)
        if (fixedShift.days[this.getWeekDay(day)] > 0)
          return true
    }
    return false;
  }

  private getFixedShiftToday(employee: MonthlyEmployee, shifts: ShiftDto[], fixedShifts: FixedShiftDto[], day: Date): ShiftDto {
    for (const fixedShift of fixedShifts)
      if (employee.id == fixedShift.userId) {
        if (fixedShift.days[this.getWeekDay(day)] > 0)
          return shifts.find(shift => shift.shiftCode == fixedShift.shiftCode)
      }
    return null;
  }

  private isSundayOrHoliday(date: Date): boolean {
    return date.getDay() == 0 || Time.isHoliday(DateTime.fromJSDate(date));
  }
}


// This is the calculation using start and end
// let end = Time.HHMMToMinutes(shift.toTime)
// const start = Time.HHMMToMinutes(shift.fromTime)
// const pause = Time.HHMMToMinutes(shift.pause)
// if ((end - start) < 0)
//   end = end + (24 * 60)
// let totalTurn = Math.max(0, ((end - start) - pause));
// totalHours += totalTurn
