import React, {Component} from 'react';
import withStatusPolling, {WithStatusPollingProps} from "../../utils/WithStatusPolling";
import {DateTime} from "luxon";
import {MonthlyDataWithStatus} from "../Dashboard/MonthlyPlanning/MonthlyDTO";
import MonthlyPlanningApi from "../../api/planning/MonthlyPlanningApi";
import WorkerRegistryApi from "../../api/worker/WorkerRegistryApi";
import MonthlyNavigationBar from "./MonthlyNavigationBar/MonthlyNavigationBar";
import EngineApi, {StatusEnum} from "../../api/engine/EngineApi";
import ShiftRegistryApi from "../../api/shift/ShiftRegistryApi";
import {WorkerDto} from "../../api/entity/WorkerDto";
import {ShiftDto} from "../../api/entity/ShiftDto";
import {MonthlyPlanningDto} from "../../api/entity/MonthlyPlanningDto";
import {WorkerResponse} from "../../api/entity/response/WorkerResponse";
import {ShiftResponse} from "../../api/entity/response/ShiftResponse";
import MonthlyPlanningWizard, {WizardStep} from "./MonthlyPlanningWizard/MonthlyPlanningWizard";

import MonthlyPlanningConvergenceCompleted
  from "./MonthlyPlanningConvergenceCompleted/MonthlyPlanningConvergenceCompleted";
import StatusPoller from "../../utils/StatusPoller";
import MonthlyPlanningTable from "./MonthlyPlanningTable/MonthlyPlanningTable";
import MonthlyPlanningTitleBar from "./MonthlyPlanningTitleBar/MonthlyPlanningTitleBar";
import './MonthlyPlanning.scss';
import MonthlyPlanningLoader from "./MonthlyPlanningLoader/MonthlyPlanningLoader";
import MonthlyTimeLineButtonBar from "./MonthlyTimeLineButtonBar/MonthlyTimeLineButtonBar";
import MonthlyPlanningNoData from "./MonthlyPlanningNoData/MonthlyPlanningNoData";
import EngineErrorsModal from "../../components/Modal/EngineErrorsModal/EngineErrorsModal";
import {FixedShiftResponse} from "../../api/entity/response/FixedShiftResponse";
import FixedShiftApi from '../../api/worker/FixedShiftApi';
import FixedShiftDtoNt from "../../api/entity/nt/FixedShiftDtoNt";
import MonthlyElapsedTable from "./MonthlyElapsedTable/MonthlyElapsedTable";
import {ElapsedEntryDto} from "../../api/entity/ElapsedEntryDto";
import ElapsedNeedApi from "../../api/planning/ElapsedNeedApi";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import SearchInput from "../../components/SearchInput/SearchInput";
import {Pagination} from "../../components/Table/Pagination";
import {OverloadedEntryDto} from "../../api/entity/OverloadedEntryDto";
import OverloadedNeedApi from "../../api/planning/OverloadedNeedApi";
import MonthlyOverloadedTable from "./MonthlyOverloadedTable/MonthlyOverloadedTable";
import I18n from '../../lib/I18n';
import {WorkerListModal} from "../../components/Modal/WorkerListModal/WorkerListModal";
import {CircuitSelect} from "../../components/CircuitSelect/CircuitSelect";
import DailyPlanningApi from "../../api/planning/DailyPlanningApi";
import {DailyPlanningWorkerDtoNt} from "../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import Spinner from "../../components/Spinner/Spinner";

interface Props extends WithStatusPollingProps {
}

interface State {
  month: DateTime,
  monthlyData: MonthlyDataWithStatus
  excludedIncompleteWorkers: WorkerDto[]
  workers: WorkerDto[]
  shifts: ShiftDto[]
  infeasible: number[]
  currentStep: WizardStep;
  engineErrors: string;
  fixedShifts: FixedShiftDtoNt[];
  lastDayMap: { [id: number]: string[] },
  view: MonthlyCompletedView,
  table: {
    currentSlice: number
    registryCount: number
  },
  searchKey: {
    planning: string,
    elapsed: string,
    overloaded: string
  },
  editMode: boolean;
  workerModal: {
    title: string,
    isOpen: boolean,
    visibleWorkerInModal: WorkerDto[]
  }
  circuitFilter: string
  elapsedEntries: ElapsedEntryDto[]
  overloadedEntries: OverloadedEntryDto[]
}

export enum MonthlyCompletedView {
  PLANNING = "Planning", OVERLOADED = "Overloaded", ELAPSED = "Elapsed"
}

class MonthlyPlanning extends Component<Props, State> {

  private elapsedNeedApi: ElapsedNeedApi = new ElapsedNeedApi();
  private overloadedNeedApi: OverloadedNeedApi = new OverloadedNeedApi();

  private elementsPerPage: number = 25;

  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    const nextMonth = DateTime.local().startOf('month').plus({months: 1});
    this.state = {
      month: nextMonth,
      excludedIncompleteWorkers: null,
      monthlyData: null,
      workers: null,
      shifts: null,
      infeasible: null,
      currentStep: WizardStep.ZERO,
      editMode: false,
      engineErrors: null,
      fixedShifts: null,
      lastDayMap: null,
      view: MonthlyCompletedView.PLANNING,
      elapsedEntries: [],
      overloadedEntries: [],
      table: {
        currentSlice: 0,
        registryCount: 0
      },
      searchKey: {
        elapsed: "",
        overloaded: "",
        planning: ""
      },
      workerModal: {
        title: "Base",
        isOpen: false,
        visibleWorkerInModal: []
      },
      circuitFilter: CircuitSelect.NO_FILTER
    }
    this.loadPlanningInfoFor(nextMonth);
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  componentDidMount() {
    this.listenerId = StatusPoller.getInstance()
      .listenToStatusChange(() => this.loadPlanningInfoFor(this.state.month))
  }

  componentWillUnmount() {
    StatusPoller.getInstance().unregisterToStatusChange(this.listenerId)
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private onResultingViewChange(viewType: MonthlyCompletedView) {
    this.setState({
      view: viewType, table: {...this.state.table, currentSlice: 0}
    }, () => {
      if (viewType == MonthlyCompletedView.ELAPSED) {
        this.reloadElapsedNeed(this.state.month, 0);
      }
      if (viewType == MonthlyCompletedView.OVERLOADED) {
        this.reloadOverloadedNeed(this.state.month, 0);
      }
    });
  }

  private onCircuitFilterChange(value) {
    this.setState({circuitFilter: value}, () => {
      const state = this.state;
      if(state.view == MonthlyCompletedView.PLANNING) {
        this.reloadOverloadedNeed(state.month, 0);
        this.reloadElapsedNeed(state.month, 0)
      }
      if (state.view == MonthlyCompletedView.OVERLOADED) {
        this.reloadOverloadedNeed(state.month, 0);
      }
      if (state.view == MonthlyCompletedView.ELAPSED) {
        this.reloadElapsedNeed(state.month, 0)
      }
    });

  }

  private reloadElapsedNeed(month: DateTime, page = this.state.table.currentSlice) {
    this.elapsedNeedApi.searchByDate(month.toFormat(GfDateUtils.STORED_DATE_FORMAT),
      this.elementsPerPage, this.state.searchKey.elapsed, this.elementsPerPage * (page),
      this.elementsPerPage, this.state.circuitFilter)
      .then(r => {
        this.setState({table: {...this.state.table, currentSlice: page}}, () => {
          this.setState({elapsedEntries: r.content, table: {...this.state.table, registryCount: r.total}})
        })
      });
  }

  private reloadOverloadedNeed(month: DateTime, page = this.state.table.currentSlice) {
    this.overloadedNeedApi.searchByDate(month.toFormat(GfDateUtils.STORED_DATE_FORMAT),
      this.elementsPerPage, this.state.searchKey.overloaded, this.elementsPerPage * (page),
      this.elementsPerPage, this.state.circuitFilter)
      .then(r => {
        this.setState({table: {...this.state.table, currentSlice: page}}, () => {
          this.setState({overloadedEntries: r.content, table: {...this.state.table, registryCount: r.total}})
        })
      });
  }

  private onSearch(value: string) {
    if (this.state.view == MonthlyCompletedView.PLANNING) {
      this.searchPlanningResults(value);
    }
    if (this.state.view == MonthlyCompletedView.ELAPSED) {
      this.setState({searchKey: {...this.state.searchKey, elapsed: value}},
        () => {
          this.reloadElapsedNeed(this.state.month, 0);
        });
    }
    if (this.state.view == MonthlyCompletedView.OVERLOADED) {
      this.setState({searchKey: {...this.state.searchKey, overloaded: value}},
        () => {
          this.reloadOverloadedNeed(this.state.month, 0);
        });
    }
  }

  private onPaginationChange(page: number) {
    this.state.table.currentSlice = page;
    const month = this.state.month;
    if (this.state.view == MonthlyCompletedView.ELAPSED) {
      this.reloadElapsedNeed(month);
    }
    if (this.state.view == MonthlyCompletedView.OVERLOADED) {
      this.reloadOverloadedNeed(month)
    }
  }

  private toggleWorkerModal(id = null) {
    if (id) {
      const overloadedEntryDto = this.state.overloadedEntries.filter(oe => oe.id == id)[0];
      const dateFormatted = DateTime.fromFormat(overloadedEntryDto.date, GfDateUtils.STORED_DATE_FORMAT).toFormat(GfDateUtils.DATE_FORMAT);
      const title = `${dateFormatted} - ${overloadedEntryDto.description} - ${overloadedEntryDto.shift}`;
      this.setState({workerModal: {title: title, visibleWorkerInModal: overloadedEntryDto.workers, isOpen: true}});
    } else {
      this.setState({workerModal: {...this.state.workerModal, isOpen: !this.state.workerModal.isOpen}});
    }
  }

  private onMonthlyDataEdit(monthlyData: MonthlyDataWithStatus) {
    new MonthlyPlanningApi().savePlanningData(this.state.month, monthlyData)
    this.setState({monthlyData});
  }

  private searchPlanningResults(value: string) {
    this.setState({searchKey: {...this.state.searchKey, planning: value}});
  }

  private getSearchSentence(view: MonthlyCompletedView | MonthlyCompletedView.PLANNING |
    MonthlyCompletedView.ELAPSED) {
    if (view == MonthlyCompletedView.OVERLOADED) {
      return I18n.get().MonthlyPlanning.viewOverloaded.search;
    } else if (view == MonthlyCompletedView.PLANNING) {
      return I18n.get().MonthlyPlanning.viewPlanning.search;
    } else {
      return I18n.get().MonthlyPlanning.viewElapsed.search;
    }
  }1

  private async loadPlanningInfoFor(month: DateTime) {
    month = month.startOf('month')

    const [workers, shifts, value, lastDayDailyWorker, fixedShifts] = await Promise.all<WorkerResponse, ShiftResponse, MonthlyPlanningDto, DailyPlanningWorkerDtoNt[], FixedShiftResponse>([
      new WorkerRegistryApi().getAllActiveInMonth(month),
      new ShiftRegistryApi().getAll(),
      new MonthlyPlanningApi().getByDate(month),
      new DailyPlanningApi().getWorkersByDate(month.minus({day: 1})),
      new FixedShiftApi().getAllFixedShifts()
    ])

    let infeasible = null;
    if (value.infeasible)
      try {
        infeasible = JSON.parse(value.infeasible)
      } catch {
      }

    let monthlyData: MonthlyDataWithStatus = null;
    let excludedWorkers = workers.content.filter(worker => worker.incomplete);

    const allWorkers = workers.content.sort((a, b) => {
      if (a.lastname < b.lastname) {
        return -1;
      } else if (a.lastname > b.lastname) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else return 0
    });

    if (value.data)
      monthlyData = this.aggregateMonthlyDataFromResponse(value, allWorkers);

    if (!monthlyData || monthlyData.status != StatusEnum.DAILY_PLANNING_COMPLETED) {
      this.setState({view: MonthlyCompletedView.PLANNING});
    }

    this.setState({searchKey: {planning: "", overloaded: "", elapsed: ""}, table: {currentSlice: 0, registryCount: 0}},
      () => {
        this.reloadElapsedNeed(month);
        this.reloadOverloadedNeed(month);
      });

    const lastDayMap = {};
    for(const entry of lastDayDailyWorker){
      if(entry.workerid){
        if(!lastDayMap[entry.workerid])
          lastDayMap[entry.workerid] = []
        lastDayMap[entry.workerid].push(entry.turn)
      }
    }

    this.setState({
      month,
      monthlyData: monthlyData,
      excludedIncompleteWorkers: excludedWorkers,
      workers: allWorkers,
      shifts: shifts.content,
      infeasible,
      currentStep: WizardStep.ZERO,
      editMode: false,
      fixedShifts: fixedShifts.content,
      lastDayMap,
    });
  }

  private aggregateMonthlyDataFromResponse(value: MonthlyPlanningDto, workers: WorkerDto[]): MonthlyDataWithStatus {
    const monthlyData = JSON.parse(value.data) as MonthlyDataWithStatus;

    let errors = {}
    if (value.errors) {
      try {
        errors = JSON.parse(value.errors);
      } catch {
        this.setState({engineErrors: value.errors})
      }
    }
    for (const data of monthlyData.employees) {
      for (const w of workers) {
        if (w.id == data.id) {
          data.name = `${w.lastname} ${w.name}`
          data.circuit = w.circuit;
          data.errors = data.id in errors ? errors[data.id] : []
        }
      }
    }
    monthlyData.status = value.status
    return monthlyData
  }

  private isNextMonth(): boolean {
    return this.state.month.equals(DateTime.local().startOf('month').plus({months: 1}))
  }

  private toggleEditMode() {
    this.setState({editMode: !this.state.editMode})
  }

  /**************************************************
   * RENDER
   **************************************************/
  public render() {
    let status = this.props.nextMonthPlanning?.status;
    const {
      currentStep,
      shifts,
      workers,
      month,
      infeasible,
      monthlyData,
      editMode,
      excludedIncompleteWorkers,
      fixedShifts,
      lastDayMap,
      view,
      workerModal,
      overloadedEntries,
      elapsedEntries,
      searchKey,
      circuitFilter
    } = this.state;

    const monthHasData = monthlyData && (monthlyData.status == StatusEnum.DAILY_PLANNING_COMPLETED || monthlyData.status == StatusEnum.MONTHLY_PLANNING_COMPLETED);
    const monthHasOverloaded = overloadedEntries.length > 0 || view == MonthlyCompletedView.OVERLOADED;
    const monthElapsed = elapsedEntries.length > 0 || view == MonthlyCompletedView.ELAPSED;

    let searchSentence = this.getSearchSentence(view);

    if (!workers || !shifts)
      return <Spinner />;

    return <div className="MonthlyPlanning">

      <MonthlyPlanningTitleBar
        step={currentStep}
        status={status}
      />

      {workerModal.isOpen ?
        <WorkerListModal workers={workerModal.visibleWorkerInModal}
                         title={workerModal.title}
                         onCancel={() => {
                           this.toggleWorkerModal()
                         }}/> : null}

      <MonthlyTimeLineButtonBar
        status={monthlyData.status} month={month} editMode={editMode}
        onToggleEditMode={() => this.toggleEditMode()}
        resetPlanning={() => this.engineApi.resetPlanning()}
        monthHasOverloaded={monthHasOverloaded}
        monthHasElapsed={monthElapsed}
        monthHasData={monthHasData}
        verifyConstraints={() => this.engineApi.checkConstraints()}
        startDailyPlanning={() => this.engineApi.startDailyPlanning()}
        view={this.state.view}
        onChangeView={(viewType) => {
          this.onResultingViewChange(viewType);
        }}
        onCircuitFilteringChange={(value) => {
          this.onCircuitFilterChange(value)
        }}
      />

      <MonthlyNavigationBar
        currentDate={this.state.month} step={currentStep}
        onMonthChanged={date => this.loadPlanningInfoFor(date)}
      />

      {monthlyData.status != StatusEnum.NO_PLANNING ?
        <SearchInput className="search-input"
                     label=""
                     defaultValue=""
                     placeholder={searchSentence}
                     value={(this.getSearchInput(view))}
                     onChange={(value) => this.onSearch(value)}
                     readonly={false} disabled={false} password={false}
                     errorMessage={""}/> : null
      }

      {this.isNextMonth()
        ? <>
          {status == StatusEnum.NO_PLANNING &&
          <div className={"outer-wizard"}>
            <MonthlyPlanningWizard
              excludedWorkers={this.state.excludedIncompleteWorkers}
              currentStep={this.state.currentStep}
              onStepChange={step => this.setState({currentStep: step})}
              month={month} workers={workers} shifts={shifts}
              fixedShifts={fixedShifts}
              lastDayMap={lastDayMap}
              circuitFilter={circuitFilter}
              onConvergenceCheck={() => this.engineApi.checkConvergence()}/>
          </div>
          }
          {status == StatusEnum.CONVERGENCE_CHECK_IN_PROGRESS && <MonthlyPlanningLoader status={status}/>}
          {status == StatusEnum.CONVERGENCE_CHECK_COMPLETED &&
          <MonthlyPlanningConvergenceCompleted
            infeasible={infeasible}
            workers={workers}
            onGoBack={() => this.engineApi.resetPlanning()}
            onConfirm={() => this.engineApi.startMonthlyPlanning()}
          />}
          {status == StatusEnum.MONTHLY_PLANNING_IN_PROGRESS && <MonthlyPlanningLoader status={status}/>}
          {status == StatusEnum.MONTHLY_PLANNING_COMPLETED &&
          <div>
            {view == MonthlyCompletedView.PLANNING &&
            <MonthlyPlanningTable
              month={month} editMode={editMode}
              monthlyData={monthlyData}
              excludedMonthlyData={excludedIncompleteWorkers}
              onMonthlyPlanningEdit={data => this.onMonthlyDataEdit(data)}
              searchValue={searchKey.planning}
              shifts={shifts}
              fixedShifts={fixedShifts}
              lastDayMap={lastDayMap}
              circuitFilter={circuitFilter}
            />}

            {view == MonthlyCompletedView.ELAPSED &&
            <div>
              <MonthlyElapsedTable
                entries={elapsedEntries}/>
              <Pagination
                page={this.state.table.currentSlice}
                onPageChange={(page) => this.onPaginationChange(page)}
                totalPages={this.state.table.registryCount}
                elementsPerPage={this.elementsPerPage}
              />
            </div>}
            {view == MonthlyCompletedView.OVERLOADED &&
            <div>
              <MonthlyOverloadedTable
                entries={overloadedEntries}
                toggleWorkerModal={(id) => {
                  this.toggleWorkerModal(id);
                }}/>
              <Pagination
                page={this.state.table.currentSlice}
                onPageChange={(page) => this.onPaginationChange(page)}
                totalPages={this.state.table.registryCount}
                elementsPerPage={this.elementsPerPage}
              />
            </div>}

          </div>}
          {status == StatusEnum.CONSTRAINT_CHECK_IN_PROGRESS && <MonthlyPlanningLoader status={status}/>}
          {status == StatusEnum.DAILY_PLANNING_IN_PROGRESS && <MonthlyPlanningLoader status={status}/>}
          {status == StatusEnum.DAILY_PLANNING_COMPLETED &&
          <div>
            {view == MonthlyCompletedView.PLANNING &&
            <MonthlyPlanningTable
              month={month}
              monthlyData={monthlyData}
              shifts={shifts}
              searchValue={searchKey.planning}
              fixedShifts={fixedShifts}
              lastDayMap={lastDayMap}
              circuitFilter={circuitFilter}
            />}
            {view == MonthlyCompletedView.ELAPSED &&
            <div><MonthlyElapsedTable entries={this.state.elapsedEntries}/>
              <Pagination
                page={this.state.table.currentSlice}
                onPageChange={(page) => this.onPaginationChange(page)}
                totalPages={this.state.table.registryCount}
                elementsPerPage={this.elementsPerPage}
              />
            </div>}
            {view == MonthlyCompletedView.OVERLOADED &&
            <div><MonthlyOverloadedTable entries={this.state.overloadedEntries} toggleWorkerModal={(id) => {
              this.toggleWorkerModal(id);
            }}/>
              <Pagination
                page={this.state.table.currentSlice}
                onPageChange={(page) => this.onPaginationChange(page)}
                totalPages={this.state.table.registryCount}
                elementsPerPage={this.elementsPerPage}
              />
            </div>}


          </div>
          }
        </>
        : <div>
          {monthHasData
            ? <div>
              {view == MonthlyCompletedView.PLANNING &&
              <MonthlyPlanningTable
                month={month}
                monthlyData={monthlyData}
                shifts={shifts}
                searchValue={searchKey.planning}
                fixedShifts={fixedShifts}
                lastDayMap={lastDayMap}
                circuitFilter={circuitFilter}
              />}
              {view == MonthlyCompletedView.ELAPSED && <div>
                <MonthlyElapsedTable entries={this.state.elapsedEntries}/>
                <Pagination
                  page={this.state.table.currentSlice}
                  onPageChange={(page) => this.onPaginationChange(page)}
                  totalPages={this.state.table.registryCount}
                  elementsPerPage={this.elementsPerPage}
                />
              </div>}
              {view == MonthlyCompletedView.OVERLOADED && <div>
                <MonthlyOverloadedTable entries={this.state.overloadedEntries} toggleWorkerModal={(id) => {
                  this.toggleWorkerModal(id);
                }}/>
                <Pagination
                  page={this.state.table.currentSlice}
                  onPageChange={(page) => this.onPaginationChange(page)}
                  totalPages={this.state.table.registryCount}
                  elementsPerPage={this.elementsPerPage}
                />
              </div>}

            </div>
            :
            <div>
              {view == MonthlyCompletedView.PLANNING &&
              <MonthlyPlanningNoData/>
              }
              {view == MonthlyCompletedView.ELAPSED && <div>
                <MonthlyElapsedTable entries={this.state.elapsedEntries}/>
                <Pagination
                  page={this.state.table.currentSlice}
                  onPageChange={(page) => this.onPaginationChange(page)}
                  totalPages={this.state.table.registryCount}
                  elementsPerPage={this.elementsPerPage}
                />
              </div>}
              {view == MonthlyCompletedView.OVERLOADED && <div>
                <MonthlyOverloadedTable entries={this.state.overloadedEntries} toggleWorkerModal={(id) => {
                  this.toggleWorkerModal(id);
                }}/>
                <Pagination
                  page={this.state.table.currentSlice}
                  onPageChange={(page) => this.onPaginationChange(page)}
                  totalPages={this.state.table.registryCount}
                  elementsPerPage={this.elementsPerPage}
                  />
              </div>}
            </div>}
        </div>}


      {!StatusPoller.getInstance().engineIsRunning() && this.state.engineErrors && <EngineErrorsModal
        engineErrors={this.state.engineErrors}
        onCancel={() => this.setState({engineErrors: null})}
      />}
    </div>
  }

  private getSearchInput(view: MonthlyCompletedView) {
    if (view == MonthlyCompletedView.PLANNING) {
      return this.state.searchKey.planning;
    }
    if (view == MonthlyCompletedView.OVERLOADED) {
      return this.state.searchKey.overloaded;
    }
    if (view == MonthlyCompletedView.ELAPSED) {
      return this.state.searchKey.elapsed;
    }
    return "";
  }

  /**************************************************
   * VARIABLES
   **************************************************/
  private engineApi = new EngineApi();
  private listenerId: string;

}

const MonthlyPlanningWithStatusPolling = withStatusPolling(MonthlyPlanning);

export default MonthlyPlanningWithStatusPolling


