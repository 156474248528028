import React from 'react';
import './TotemWorkers.scss';
import Page from "../Page";
import I18n from "../../lib/I18n";
import WorkersTable, {WTEntry} from "./WorkersTable/WorkersTable";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import UserDailyShiftApi from "../../api/user/UserDailyShiftApi";
import {UserDailyShiftDto} from "../../api/entity/UserDailyShiftDto";
import {EntryMapper} from "./EntryMapper";
import SearchInput from "../../components/SearchInput/SearchInput";
import {CircuitSelect} from "../../components/CircuitSelect/CircuitSelect";

interface State {
  date: DateTime,
  searchFilter: string,
  circuitFilter: string,
  dailyContent: UserDailyShiftDto[],
  tableContentMapping: WTEntry[]
}

export default class TotemWorkers extends Page<{}, State> {

  private refreshIntervalTimer: any;

  private userDailyShiftApi: UserDailyShiftApi = new UserDailyShiftApi();
  private readonly msInFiveMinutes = 1000 * 5 * 60;

  public constructor(props) {
    super(props);
    this.state = {
      date: DateTime.now(), //DateTime.fromFormat("2022-01-28", GfDateUtils.STORED_DATE_FORMAT), //DateTime.now(), //
      dailyContent: [],
      searchFilter: "",
      circuitFilter: CircuitSelect.NO_FILTER,
      tableContentMapping: []
    }
  }

  componentDidMount() {
    this.loadEntriesForDay();
    this.refreshIntervalTimer = setInterval(() => this.onRefresh(), this.msInFiveMinutes)
  }

  private onRefresh() {
    if (this.state.searchFilter.length > 0) {
      this.onSearchAndFilter(this.state.searchFilter);
    } else {
      this.loadEntriesForDay()
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalTimer);
  }

  private loadEntriesForDay() {
    this.userDailyShiftApi.getByDate(this.state.date).then((resp) => {
      this.setState({
        dailyContent: resp.userDailyShifts,
        tableContentMapping: resp.userDailyShifts
          .map((e) => EntryMapper.mapDateToTableEntry(e))
      })
    });
  }

  private onCircuitFilter(value: string) {
    this.setState({
      circuitFilter: value, tableContentMapping: this.filterEntries(
        this.state.searchFilter, value,
        this.state.dailyContent)
    })
  }

  private onSearchAndFilter(value: string) {
    let electedValueForSearch = value.trim();
    this.setState({
      searchFilter: electedValueForSearch, tableContentMapping:
        this.filterEntries(electedValueForSearch, this.state.circuitFilter, this.state.dailyContent)
    });
  }

  private filterEntries(filter: string, circuit: string, dailyContent: UserDailyShiftDto[]): WTEntry[] {
    return dailyContent
      .filter(d => this.isNameOrSurnameInOptions(filter, [d.operator, d.operator2, d.operator3]))
      .filter(d => circuit == CircuitSelect.NO_FILTER || d.circuit.toLowerCase() == circuit.toLowerCase())
      .map((it) => EntryMapper.mapDateToTableEntry(it))
  }

  private isNameOrSurnameInOptions(filter: string, names: string[]) {
    return names.map(n => n.toLowerCase().indexOf(filter.toLowerCase()))
      .filter(r => r > -1).length > 0
  }

  render() {
    let {date, tableContentMapping} = this.state;
    let formattedDate = date.setLocale("it").toFormat(GfDateUtils.FULL_READABLE_DATE);

    return (
      <div className={"TotemWorkers"}>
        <div className={"inner-content"}>
          <div className={"top"}>
            <div className={"up-title"}>
              <h1>{I18n.get().Menu.totemWorkers}</h1>
            </div>
          </div>
          <div className={"contents"}>
            <div className={"top"}>
              <h2 className={"date"}>{formattedDate}</h2>
            </div>
            <div className={"controls"}>
              <SearchInput className={"search-bar"} label={""}
                           defaultValue={""}
                           placeholder={I18n.get().TotemWorkers.search.placeholder}
                           onChange={(value) => {
                             this.onSearchAndFilter(value);
                           }} readonly={false}
                           disabled={false} password={false}
                           errorMessage={""}/>

              <CircuitSelect disabled={false}
                             onChange={(value) => this.onCircuitFilter(value)}
                             value={this.state.circuitFilter}
              />
            </div>
            <span className={"table"}>
                         <WorkersTable
                           entries={tableContentMapping}/>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
