import React from 'react';
import './Login.scss';
import Page from "../Page";
import LoginForm from "../../components/LoginForm/LoginForm";
import LoginApi from "../../api/login/LoginApi";
import {Pages} from "../../router/Pages";
import {Roles} from "../../api/Roles";

export default class Login extends Page {

  private loginApi = new LoginApi();

  async componentDidMount() {
    const isAuth = await this.loginApi.testSession();
    if (isAuth) {
      this.redirectToPage();
    }
  }

  private redirectToPage() {
    if(LoginApi.getRole() == Roles.admin) {
      this.props.history.push(Pages.MONTHLY_PLANNING);
    } else {
      this.props.history.push(Pages.TOTEM_WORKERS);
    }
  }

  render() {
    return (
      <div className="Login">
        <h1 className="logo">Ulisse</h1>
        <LoginForm className="LoginForm" onSuccessLogin={() => this.redirectToPage()}/>
      </div>
    );
  }
}
