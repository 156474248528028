export enum Pages {

  LOGIN = "/",

  PLANNING = "/planning",
  DAILY_PLANNING = "/planning_daily",
  MONTHLY_PLANNING = "/planning_monthly",

  WORKERS_REGISTRY = "/workers-registry",
  VEHICLE_REGISTRY = "/vehicle-registry",
  SHIFT_REGISTRY = "/shift-registry",
  NEED_MANAGEMENT = "/need-management",
  VEHICLE_MANAGEMENT = "/vehicle-management",
  NEED_MANAGEMENT_MODAL = "/need-management/modal",
  NEED_MANAGEMENT_MODIFY = "/need-management/modal/:id",
  RADIO_CODE = "/radio-code",
  TOOLS = "/tools",

  TOTEM_WORKERS = "/totem/workers",
  TOTEM_MANAGERS = "/totem/managers",
  TOTEM = "/totem",

}
