import AuthorizeApi from "../AuthorizeApi";
import {DateTime} from "luxon";
import {
  DailyPlanningWorkerDto,
  DailyPlanningWorkerDtoNt,
  DailyPlanningWorkerResponse,
  DailyPlanningWorkerResponseNt
} from "../entity/response/nt/DailyPlanningWorkerResponseNt";
import {DailyFindingRequest} from "../entity/request/DailyFindingRequest";
import Time from "../../lib/utils/Time";

export default class DailyPlanningApi {

  private base: string = '/daily-planning-worker';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getWorkersByDate(date: DateTime): Promise<DailyPlanningWorkerDtoNt[]> {
    const response = await this.authApi.get<DailyPlanningWorkerResponseNt>(`${this.base}/${date.toFormat("yyyy-MM-dd")}`);

    const dailyWorkers = []

    for(const d of response.content){
      if(d.date == date.toFormat("yyyy-MM-dd")){
        dailyWorkers.push(d)
      }
    }

    for(const d of response.content){
      if (d.date == date.minus( {day:1}).toFormat("yyyy-MM-dd")){
        if(d.shiftstart && d.shiftend && Time.hourStringToNumber(d.shiftstart)>=Time.hourStringToNumber(d.shiftend)){
          d.shiftstart="00:00:00"
          d.servicestart="00:00:00"
          d.date=date.toFormat("yyyy-MM-dd")
          d.previousDay = true;
          dailyWorkers.push(d)
        }
      }
    }

    return dailyWorkers
  }

  public async getByWorkerIdAndDate(workerId: number, date: DateTime): Promise<DailyPlanningWorkerResponse> {
    let response;
    await this.authApi.post<DailyFindingRequest, DailyPlanningWorkerResponseNt>(`${this.base}/find`, {
      date: date.toFormat("yyyy-MM-dd"),
      workerId: workerId
    })
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      });
    return response
  }

  public async getContainerVehicle(date: DateTime): Promise<DailyPlanningWorkerResponse> {
    let response;
    await this.authApi.get<DailyPlanningWorkerResponseNt>(`${this.base}/container-vehicle/${date.toFormat("yyyy-MM-dd")}`)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  public async create(record: DailyPlanningWorkerDto) {
    let response;
    await this.authApi.post<DailyPlanningWorkerDto, DailyPlanningWorkerResponseNt>(`${this.base}`, record)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  public async delete(id: number) {
    let response;
    await this.authApi.delete<void, DailyPlanningWorkerResponseNt>(`${this.base}/` + id)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  public async deleteByWorkerNameAndDate(fullName: string, day: DateTime) {
    let response;
    let dateFormatted = day.toFormat("yyyy-MM-dd");
    await this.authApi.delete<void, DailyPlanningWorkerResponseNt>(`${this.base}/worker/${fullName}/${dateFormatted}`)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;

  }

  public async update(record: DailyPlanningWorkerDto) {
    let response;
    await this.authApi.put<DailyPlanningWorkerDto, DailyPlanningWorkerResponseNt>(`${this.base}`, record)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  private static mapResponse(w: DailyPlanningWorkerResponseNt): DailyPlanningWorkerResponse {
    return {
      content: w.content.map(r => {
        return {
          id: r.id,
          date: r.date,
          companionid: r.companionid,
          workerid: r.workerid,
          workername: r.workername,
          containervehicle: r.containervehicle,
          shiftstart: r.shiftstart ? r.shiftstart.slice(0, 5) : "00:00",
          shiftend: r.shiftend ? r.shiftend.slice(0, 5) : "00:00",
          servicestart: r.servicestart ? r.servicestart.slice(0, 5) : "00:00",
          serviceend: r.serviceend ? r.serviceend.slice(0, 5) : "00:00",
          service: r.service,
          turn: r.turn,
          vehicle: r.vehicle,
          servicecategory: r.servicecategory,
          manuallyCreated: r.manuallyCreated,
          radiocodeid: r.radiocodeid
        }
      })
    }
  }

  public static getDefaultEmptyEntry(date: DateTime, workerId: number, workerName: string, containerVehicle? : string) : DailyPlanningWorkerDtoNt  {
    let defaultTime = "00:00";
    return {
      id: null,
      date: date.toFormat("yyyy-MM-dd"),
      companionid: null,
      workerid: workerId,
      workername: workerName,
      containervehicle: containerVehicle ? containerVehicle : null,
      shiftstart: defaultTime,
      shiftend: defaultTime,
      servicestart: defaultTime,
      serviceend: defaultTime,
      service: null,
      turn: null,
      vehicle: null,
      servicecategory: null,
      radiocodeid: 0,
      manuallyCreated: true
    }
  }
}
